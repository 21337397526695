@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #14452F;
  border-radius: 3px;
}

body {
  /* color: rgb(var(--foreground-rgb)); */
  background-color: rgb(20, 20, 20);
  scrollbar-gutter: stable both-edges;
}
